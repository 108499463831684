body {
  background-image: url("background.jpg");
}

.logout-box {
  background-color: white;
  opacity: 85%;
  border-radius: 5px;
  padding: 5px;
}

.box {
  min-height: 48px;
}

.button-box {
  border-radius: 25px;
  background-color: #04aa6d !important;
  color: white !important;
  border: 0px;
}

.button-box-type{
  border-radius: 25px;
  background-color: #04aa6d !important;
  color: white !important;
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
  border: 0px;
}

.logout-button {
  font-size: 16px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: bold;
}

.delete-button {
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
}

.background-orange {
  background-color: orange;
}
.background-green {
  background-color: palegreen;
}
.background-yellow {
  background-color: yellow;
}

.background-white {
  background-color: white;
}

.text-size {
  font-size: 24px;
}

.border-bottom-red {
  border-bottom: 3px solid red;
}

.input-text {
  padding-left: 25px !important;
} 


.border-radius-input {
  border-radius: 10px;
}

.nameUser {
  background-color: rgb(196, 194, 194);
}

.max-hight-todo{
  max-height: 49vh;
  overflow: auto;
  overflow-x: hidden;
}

.max-hight-todo::-webkit-scrollbar {
  width: 10px;
}

.max-hight-todo::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
.max-hight-todo::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 25px;
}

.max-hight-todo::-webkit-scrollbar-thumb:hover {
  background: #555; 
}